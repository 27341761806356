import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_1/sub_2/slide1';
// import BackgroundAnimation from '../../../../components/desktop/backgroundAnimation/backgroundAnimation.jsx';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      leftColumn: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-1-2-1-1"}}) {
        body
      }
      animation: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "slide-1-2-1-2" }
        }
      ) {
        body
      }
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
